body {
  background-color: #d88f65 !important;
  height: 100%;
  background: url(./bg.png) no-repeat center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-size: 2em;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.bg {
  background: rgb(242, 242, 242, 0.14);
  width: 400px;
  height: 480px;
  margin-top: -80px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardBg {
  margin-top: -100px;
}

.inCard {
  background: rgb(242, 242, 242, 0.14);
  border-radius: 30px;
  height: 400px;
}
.inCardNoHeight {
  background: rgb(242, 242, 242, 0.14);
  border-radius: 30px;
  /*   height: 400px;
 */
}

.inCardSSS {
  background: rgb(242, 242, 242, 0.14);
  border-radius: 30px;
  min-height: 100px;
  margin-bottom: 20px;
}
.cardNumberContainer {
  position: absolute;
  bottom: 0;
  left: 40px;
}

.cardContent {
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff5e7;
  flex-direction: column;
  text-align: center;
  padding: 100px 40px 40px 40px;
}

.cardContent a {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 25px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: #fff5e7;
}

.cardContent a:hover {
  text-decoration: none;
  color: #fff5e7;
}
.cardContent h2 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin-top: 20px;
}

.cardContent span {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.cardContent h1 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
}
.cardContent h2 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.cardContent h1 span {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

/* LOGİNED */

.cardBgLogined {
  cursor: pointer;
}

.cardContentLogined {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff5e7;
  flex-direction: column;
  text-align: center;
  padding: 0 40px 0 40px;
}

.cardContentLogined a {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 25px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: #fff5e7;
}

.cardContentLogined a:hover {
  text-decoration: none;
  color: #fff5e7;
}
.cardContentLogined h2 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin-top: 20px;
}

.cardContentLogined span {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.cardContentLogined h1 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
}
.cardContentLogined h2 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.cardContentLogined h1 span {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 20px;
}
/* LOGİNED */
.cardNumber {
  color: #fff5e7;
  opacity: 0.9;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 86px;
}

.logo {
  width: 400px;
  height: 480px;
  margin-top: -80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.keshis {
  margin-top: -70px;
}

.registerContainer {
  flex-direction: column;
  text-align: center;
}

.registerContainer h1 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 25px;
  color: #fffdf3;
  margin: 10px 0 5px 0;
}

.inputContainer {
  border-radius: 8px;
  background-color: rgb(240, 220, 203, 0.2);
  height: 40px;

  display: flex;
  align-items: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin: 5px 0 0 0;
  padding-left: 10px;
}
.inputMultilineContainer {
  border-radius: 8px;
  background-color: rgb(240, 220, 203, 0.2);
  height: 85px;

  display: flex;
  align-items: center;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin: 5px 0 0 0;
}
.inputMultilineContainerSurvey {
  border-radius: 30px;
  background-color: rgb(240, 220, 203, 0.2);
  height: 285px;

  display: flex;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.input {
  color: #fff7ef;
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border: none;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0 10px 2px 10px;
}
.inputMultiline {
  color: #fff7ef;
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 85px;
  box-sizing: border-box;
  border: none;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 5px 10px 5px 10px;
  resize: none;
}
.inputMultilineSurvey {
  color: #fff7ef;
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 285px;
  box-sizing: border-box;
  border: none;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 15px;
  resize: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 247, 239, 0.5);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(255, 247, 239, 0.5);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(255, 247, 239, 0.5);
}

.buttonContainer {
  cursor: pointer;
  background-color: rgb(240, 220, 203, 0.2);
  border-radius: 8px;
  width: 100%;
  height: 40px;
}

.buttonContainer h1 {
  color: #f2f2f2;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 25px;
  height: 40px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.buttonContainerFull h1 {
  color: #f2f2f2;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 25px;
  height: 40px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.buttonContainerFull {
  cursor: pointer;
  background-color: rgb(240, 220, 203, 0.2);
  border-radius: 8px;
  width: 100%;
  margin: 0 15px 0 15px;
  height: 40px;
}

.contract {
  margin: 0;
  padding: 0;
  color: #fffdf3;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 18px;
  cursor: pointer;
  text-align: left;
}

.contract a {
  color: #fffdf3;
  text-decoration: none;
}
.contract a:hover {
  color: #fffdf3;
  text-decoration: none;
}

.checkBox {
  border-width: 1px;
  border-style: solid;
  border-color: #fffdf3;
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.checkBoxChecked {
  background-color: #fffdf3;
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.socialButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialText1 {
  color: #fffdf3;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 18px;
  padding-bottom: 5px;
}
.socialText2 {
  color: #000 !important;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 18px;
  padding-bottom: 7px !important;
}

.alertSuccessTitle {
  color: #4cae4c !important;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 32px;
}
.alertErrorTitle {
  color: #d9534f !important;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 32px;
}
.alertContent {
  color: #000 !important;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  font-size: 25px;
}

.surveyBg {
  background: rgb(242, 242, 242, 0.14);
  border-radius: 30px;
  min-height: 200px;
}

.rec.rec-arrow {
  border-radius: 50%;
  background-color: rgb(242, 242, 242, 0.14);
  box-shadow: 0 0 0px 0px #333 !important;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
  border-radius: 50%;
  background-color: rgb(242, 242, 242) !important;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}

.carouselButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carouselButtonContainer {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(242, 242, 242, 0.14);
  cursor: pointer;
  color: #fff;
  font-size: 25px;
}
.carouselButtonContainer:hover {
  background-color: rgb(242, 242, 242);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  color: #d88f65;
}

.carouselIconRight {
  margin-left: 3px;
}
.carouselIconLeft {
  margin-left: -3px;
}

.questionWrapper {
  margin-top: -100px;
}

.questionNumber {
  color: #fff5e7;
  opacity: 0.9;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 46px;
}

.surveyQuestion {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 26px;
  color: #f9eee7;
}

.modern-radio-container {
  margin: 20px 0;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: flex-start;
}

.radio-outer-circle {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border: 2px solid #fcf8f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: all 0.1s linear;
}

.radio-inner-circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fcf8f5;
  transition: all 0.1s linear;
}
.unselected {
  border: 2px solid #f6eae3;
}
.unselected-circle {
  width: 0;
  height: 0;
}
.helper-text {
  color: #fbf4ef;
  padding-right: 8px;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 400;
  font-size: 26px;
  margin-top: -13px;
}
.faq-row-wrapper .faq-body .faq-row {
  border-bottom: 0 !important;
  padding: 0 !important;
}

.faq-row-wrapper .faq-body .faq-row .row-title {
  color: #fbf4ef !important;
  font-family: 'Barlow Condensed', sans-serif !important;
  font-weight: 600 !important;
  font-size: 22px !important;
}
.faq-row-wrapper .faq-body .faq-row .row-content .row-content-text {
  color: #fbf4ef !important;
  font-family: 'Barlow Condensed', sans-serif !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.faq-row-wrapper .faq-body .faq-row .row-title {
  fill: var(--arrow-color, white) !important;
}

.flip-container {
  perspective: 1000px;
}
/* flip the pane when hovered */
.flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  width: 100%;
  min-height: 375px;
  /*   margin-bottom: 10px;
 */
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;

  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
}

@media screen and (max-width: 992px) {
  .bg {
    width: 100%;
    height: 550px;
  }

  .socialButton {
    flex-direction: column;
    color: red;
  }

  .keshis {
    margin-top: -100px;
  }

  .cardBg {
    margin: -100px 0px 110px 0px;
  }

  .inCard {
    min-height: 350px;
  }

  .inputContainer {
    margin: 15px 0 0 0;
  }

  .rec.rec-arrow {
    display: none;
  }

  .mobileNotShown {
    display: none;
  }
}
