.navbar {
  height: 80px;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
}

.navbar-logo {
  color: #f2f2f2;
  opacity: 0.82;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 72px;
  display: flex;

  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.navbar-logo:hover {
  color: #f2f2f2;
  transition: all 0.2s ease-out;
  text-decoration: none;
}

.nav-menu {
  display: flex;
  text-align: center;
  justify-content: center;
  flex: 1;
  margin: 0;
  padding: 0;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  flex: 1;
  justify-content: center;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 26px;
}

.nav-links:hover {
  transition: all 0.2s ease-out;
  text-decoration: none;
  color: #fff;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}
.menu-icon2 {
  display: none;
}

@media screen and (max-width: 992px) {
  .nav-menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;

    top: 0px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #d88f65;

    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    padding: 2rem;
  }

  .navbar-logo {
    display: none;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 2;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .menu-icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
    font-size: 50px;
    cursor: pointer;
  }
  .menu-icon2 a {
    text-decoration: none;
    color: #f2f2f2;
    opacity: 0.82;
  }
  .menu-icon2 a:hover {
    text-decoration: none;
    color: #f2f2f2;
    opacity: 0.82;
  }
}
